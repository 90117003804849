<template>
  <div class="box">
    <div class="video-top">
      <div class="video-l">
        <img src="@/assets/img/goBack.png" alt="" @click="goBack" />
        <div class="one">
          <!-- {{ tabName }} <span v-if="tabName">></span>
          <span class="two">
            {{ info.title || "" }}
          </span> -->
          <span>{{ courseData.title }}</span>
          <span class="now"> 正在播放 </span>
        </div>
      </div>
    </div>
    <div class="fullScreen">
      <div class="videoContent">
        <div class="videoContent-l">
          <div v-show="showLogo" class="logo">
            <img v-if="logo" :src="logo" alt="" style="height: 54px" />
            <img
              v-else
              src="@/assets/img/headLogo.jpg"
              alt=""
              style="height: 54px"
            />
          </div>

          <div v-if="playerShow" id="avideo" class="prism-player"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "viewCourseware",
  props: ["videoData", "id"],
  filters: {
    formatRate: function (value) {
      if (value == null || value == "0%") {
        return `未学习`;
      } else if (value == "100%") {
        return `已学完`;
      } else {
        return `已学${value}`;
      }
    },
    formatSeconds: function (value) {
      var theTime = parseInt(value); // 秒
      var middle = 0; // 分
      var hour = 0; // 小时

      if (theTime > 60) {
        middle = parseInt(theTime / 60);
        theTime = parseInt(theTime % 60);
        if (middle > 60) {
          hour = parseInt(middle / 60);
          middle = parseInt(middle % 60);
        }
      }
      var result = "" + parseInt(theTime) + "";
      if (middle > 0) {
        result = "" + parseInt(middle) + ":" + result;
      }
      if (hour > 0) {
        result = "" + parseInt(hour) + ":" + result;
      }
      return result;
    },
  },
  data() {
    return {
      activeNames: [0],
      isshow: false,
      courseId: "",
      lessonId: "",
      chapterId: "",
      info: {},
      player: null,
      playerShow: true,
      tipShow: false,
      // 用户信息
      userInfo: {},
      studyLogId: "",
      status: "",
      times: 5, // 5秒倒计时
      tabName: "",
      logo: "",
      showLogo: false,
      activeName: "first",
      timer: null,
      activeNum: 0,
      courseData: null, // 课程数据
      recordId: null, // 轮询id
      interval: null, //定时器
      itemInfo:{}
    };
  },
  watch: {
    videoData: {
      immediate: true,
      handler(newVal, oldVal) {
        this.courseData = newVal;
        if (newVal) {
          this.toVideoSrc(newVal);
        }
      },
    },
  },
  mounted() {},
  beforedestroy() {
    // this.onPlayerEnded();
    // if (this.player !== null) {
    //   this.player.pause();
    //   this.player = null;
    // }
    this.disposeVideo();
    this.$emit("closeVideo");
  },
  methods: {
    disposeVideo() {
      // 销毁之前的视频，不销毁的话，它会一直存在
      if (this.player !== null && this.player) {
        if (this.itemInfo.sourceType === 1) {
          this.player.pause()
          this.player.destroy()
        } else {
          this.player.pause()
          this.player.dispose()
        }
        this.player = null
      }
    },
    getCourseTree() {
      this.courseId = this.id;
      this.$request.getScCourseTree(this.courseId).then((res) => {
        if (res.data.data.data.length > 0) {
          this.info = res.data.data.data[0];
          console.log(this.info);
          if (this.info.chapterList.length == 0) {
            this.$message({
              message: "暂无视频可播放",
              type: "warning",
            });
          }
        } else {
          this.$message({
            message: "暂无视频可播放",
            type: "warning",
          });
        }
      });
    },
    toVideoSrc(ele) {
      console.log(ele);
      this.lessonId = ele.id;
      console.log("点击目录播放视频");
      this.videoSrc(ele, (player) => {
        // this.player = player
      });
      // getCourseTree(_, this.courseId).then(res => {
      //   this.info = res.data.data[0]
      // }) // 切换视频时播放进度也要更新
    },
    videoSrc(ele, callback) {
      // this.lastId = this.lessonId;
      this.lessonId = ele.id;
      // 开始进来调用的视频接口
      this.$request.getPlayAuth({ videoId: ele.mediaUri }).then((res) => {
        // this.player.dispose();是销毁之前的视频，不销毁的话，它会一直存在
        this.disposeVideo()
        // this.studyLogId = res.data.studyLogId;
        this.itemInfo = res.data.data
        if (res.data.data.sourceType === 1) {
            // 慕课播放
            this.player = window.EduPlayer.create({
              parent: document.getElementById('avideo'), // 播放器参数
              autoStart: true,
              videoData: {
                videoId: ele.mediaUri, // 从后端获取，详见参数说明
                signature: res.data.data.playAuth,
              },
            })
          } else {
            this.player = new Aliplayer(
              {
                id: "avideo",
                width: "100%",
                height: "100%",
                autoplay: false,
                encryptType: 1,
                vid: ele.mediaUri,
                playauth: res.data.data.playAuth,
                cover: "",
              },
              function (player) {
                console.log("播放器创建好了。");
              }
            );
            // this.player.on('play', this.onPlayerPlay)
            // this.player.on('pause', this.onPlayerPause)
            // this.player.on('ended', this.onPlayerEnded)
            // this.player.on('ready', this.onPlayerReady) // 能够开始播放视频
    
            callback(this.player);
          }
      });
    },

    goBack() {
      if (this.player !== null) {
        this.player.pause();
        this.player = null;
      }
      this.$emit("closeVideo");
      // this.$router.go(-1);
    },
  },
};
</script>
<style lang="less" scoped>
#avideo {
  min-height: 100%;
}
::v-deep #avideo .ux-video-player {
  height: 500px;
}
.box {
  width: 100%;
  height: 100%;
  margin-bottom: 25px;
  .video-top {
    height: 80px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    box-sizing: border-box;
    padding: 25px;
    .hangUpTip {
      display: flex;
      align-items: center;
      height: 26px;
      width: 690px;
      background: rgba(226, 117, 26, 0.1);
      border-radius: 4px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      line-height: 26px;
      color: #e2751a;
      img {
        margin: 0 10px;
      }
    }
    .tabLists {
      color: #fff;
      border: 1px solid #fff;
      display: flex;
      .list {
        width: 80px;
        text-align: center;
        border-right: 1px solid #fff;
        padding: 5px 0;
        &:hover {
          cursor: pointer;
        }
        &.active {
          background-color: #0066ff;
        }
      }
    }
    .video-l {
      display: flex;
      align-items: center;
      img {
        &:hover {
          cursor: pointer;
        }
      }
      .one {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        padding-left: 20px;
        color: #363840;
        .two {
          color: #ffffff;
        }
        .now {
          color: #17a5f2;
        }
      }
    }
    .video-r {
      display: flex;
      align-items: center;
      img {
        width: 28px;
        height: 28px;
        border-radius: 50%;
        overflow: hidden;
      }
      &:hover {
        cursor: pointer;
      }
      span {
        font-size: 18px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #999999;
      }
    }
  }
  .fullScreen {
    overflow: hidden;

    width: 100%;
    height: 500px;
    box-sizing: border-box;
    padding: 0 25px;
    .videoContent {
      height: 100%;
      display: flex;
      .videoContent-l {
        position: relative;
        flex: 1;
        #avideo {
          position: relative;
        }
        .handUpBox {
          position: absolute;
          width: 306px;
          height: 68px;
          background: rgba(0, 0, 0, 0.8);
          border-radius: 10px;
          left: 16px;
          bottom: 56px;
          // opacity: 0.8;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          z-index: 9999;
          padding: 10px;
          box-sizing: border-box;
          display: flex;
          align-items: center;

          p {
            span {
              color: rgba(255, 0, 0, 1);
            }
            padding-right: 10px;
          }
          .el-button {
            width: 80px;
            height: 32px;
          }
          &.handUpBoxFull {
            position: fixed;
            bottom: 50px;
            left: 16px;
          }
        }
        .modeSwitch {
          position: absolute;
          right: 320px;
          top: 20px;
          z-index: 9999;
          width: 228px;
          height: 44px;
          background: #17a5f2;
          border-radius: 6px;
          display: flex;
          align-items: center;
          .preview {
            flex: 1;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
              cursor: pointer;
            }
          }
          .study {
            width: 112px;
            height: 36px;
            background: #ffffff;
            border-radius: 5px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #17a5f2;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 2px;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .logo {
          position: absolute;
          right: 100px;
          top: 15px;
          z-index: 99999;
        }
        .tip {
          top: 300px;
          left: 50%;
          z-index: 99;
          transform: translateX(-50%);
          position: absolute;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bbbbbb;
        }
        .icon {
          z-index: 99;
          position: absolute;
          width: 400px;
          height: 150px;
          top: 400px;
          left: 50%;
          transform: translateX(-50%);
          display: flex;
          justify-content: space-between;
          .icon-l {
            width: 96px;
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #bbbbbb;
            &:hover {
              cursor: pointer;
            }
          }
          .icon-r {
            width: 96px;
            text-align: center;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #bbbbbb;
            &:hover {
              cursor: pointer;
            }
          }
        }
        .nav {
          position: absolute;
          right: 0;
          top: 0;
          width: 48px;
          height: 530px;
          padding: 150px 0;
          background: #1b1b1b;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .navList {
            display: flex;
            flex-direction: column;
            align-items: center;
            &:hover {
              cursor: pointer;
            }
            img {
              width: 18px;
              height: 18px;
            }
            span {
              font-size: 14px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #aaaaaa;
            }
          }
        }
      }
      .videoContent-r {
        width: 340px;
        height: 100%;
        padding-left: 20px;
        background: #2c2c2c;
        .videoContent-t {
          height: 47px;
          display: flex;
          align-items: center;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 340px;
            height: 1px;
            background: #393939;
            border-radius: 1px;
          }
          span {
            margin-left: 8px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #ffffff;
          }
        }
        .coll {
          height: calc(100% - 47px);
          overflow: auto;
          /deep/ .el-collapse {
            border-top: none;
          }
          /deep/ .el-collapse-item__header {
            background: #2c2c2c;
            border-bottom: 1px solid #393939;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
            height: auto;
            // white-space: nowrap;
            // text-overflow: ellipsis;
            // overflow: hidden;
            // word-break: break-all;
          }
          /deep/ .el-collapse-item__wrap {
            background: #2c2c2c;
            border-bottom: 1px solid #393939;
          }
          /deep/ .el-collapse-item__content {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #aaaaaa;
          }
          ul {
            li {
              display: flex;
              padding: 15px 0;
              .act-l {
                width: 60px;
                display: flex;
                justify-content: center;
                .progress {
                  width: 60px;
                  height: 20px;
                  background: #393939;
                  border-radius: 4px;
                  text-align: center;
                  line-height: 20px;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #aaaaaa;
                }
              }
              .play {
                display: none;
                width: 20px;
                height: 20px;
              }
              &:hover {
                span {
                  color: #17a5f2;
                }
                .play {
                  display: block;
                }
              }
              &.active {
                span {
                  color: #17a5f2;
                }
                background: #393939;
                border-radius: 4px;
              }
            }
          }
        }
        .collect {
          margin-top: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            margin-left: 8px;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
          }
        }
      }
    }
  }
  .class {
    .nav {
      display: flex;
      height: 66px;
      width: 1200px;
      margin: 0 auto;
      justify-content: space-between;
      align-items: center;
      .nav-l {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #888888;
      }
      .nav-r {
        width: 194px;
        height: 32px;
        background: #17a5f2;
        border-radius: 6px;
        display: flex;
        align-items: center;
        .yulan {
          width: 96px;
          height: 28px;
          background: #ffffff;
          border-radius: 5px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #17a5f2;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 2px;
          &:hover {
            cursor: pointer;
          }
        }
        .xue {
          flex: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
    .content {
      width: 1200px;
      height: 540px;
      background: #282828;
      border-radius: 2px 6px 6px 2px;
      margin: 0 auto 60px;
      display: flex;
      .content-l {
        width: 828px;
        height: 540px;
        position: relative;
        .tip {
          top: 200px;
          left: 50%;
          z-index: 99;
          transform: translateX(-50%);
          position: absolute;
          font-size: 20px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #bbbbbb;
        }
        .prism-player {
          width: 100%;
          height: 100%;
        }
      }
      .content-r {
        flex: 1;
        padding: 0 20px;
        overflow-y: auto;
        overflow-x: hidden;
        .videoContent-t {
          height: 70px;
          display: flex;
          align-items: center;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            width: 340px;
            height: 1px;
            background: #393939;
            border-radius: 1px;
          }
          span {
            margin-left: 8px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 300;
            color: #ffffff;
          }
        }
        .coll {
          height: 750px;
          overflow: auto;
          /deep/ .el-collapse {
            border-top: none;
          }
          /deep/ .el-collapse-item__header {
            background: #2c2c2c;
            border-bottom: 1px solid #393939;
            height: auto;
            font-size: 16px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
          /deep/ .el-collapse-item__wrap {
            background: #2c2c2c;
            border-bottom: 1px solid #393939;
          }
          /deep/ .el-collapse-item__content {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #aaaaaa;
            padding-bottom: 0;
          }
          ul {
            li {
              display: flex;
              padding: 15px 0;
              .act-l {
                width: 60px;
                display: flex;
                justify-content: center;
                .progress {
                  width: 60px;
                  height: 20px;
                  background: #393939;
                  border-radius: 4px;
                  text-align: center;
                  line-height: 20px;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #aaaaaa;
                }
              }
              .play {
                display: none;
                width: 20px;
                height: 20px;
              }
              &:hover {
                span {
                  color: #17a5f2;
                }
                .play {
                  display: block;
                }
              }
              &.active {
                span {
                  color: #17a5f2;
                }
                background: #393939;
                border-radius: 4px;
              }
            }
          }
        }
      }
    }
    .classContent {
      width: 1120px;
      margin: 30px auto 0;
      padding: 10px 40px 30px;
      background: #ffffff;
      border-radius: 6px;
      margin-bottom: 100px;
      .bjxq {
        p {
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #666666;
          line-height: 32px;
        }
      }
    }
  }
}
</style>
<style lang="less">
.prism-setting-item.prism-setting-cc {
  display: none !important;
}
.prism-setting-item.prism-setting-audio {
  display: none !important;
}
.prism-cc-btn {
  display: none !important;
}
.fullScreen {
  div::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    /**/
  }
  div::-webkit-scrollbar-track {
    background: #393939;
    border-radius: 2px;
  }
  div::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 2px;
  }
  // div::-webkit-scrollbar-thumb:hover {
  //   background: #333;
  // }
  // div::-webkit-scrollbar-corner {
  //   background: #179a16;
  // }
}
</style>
