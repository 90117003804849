<template>
  <div class="myTree">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item
        v-for="(item, index) in list"
        :key="index"
        :name="item.id"
      >
        <div slot="title" class="title">
          <span class="title_icon"></span>{{ item.title }}
        </div>
        <div
          class="collapse_second"
          v-for="ite in item.lessonList"
          :key="ite.id"
          @click="videoHandle(ite)"
        >
          <div
            :class="['content', isPlayId == ite.id ? 'isPlay' : '']"
            slot="content"
          >
            <div class="content_box">
              <p>
                <img src="../../../assets/course/video3.png" class="unplay" />
                <img class="isplay" src="../../../assets/course/video2.png" />
                {{ ite.title }}
                <span
                  >（课程时长：{{
                    [
                      $moment.duration(ite.length, "seconds").hours(),
                      $moment.duration(ite.length, "seconds").minutes(),
                      $moment.duration(ite.length, "seconds").seconds(),
                    ].join(":")
                  }}）</span
                >
              </p>
              <!-- <p>
                <span>课程进度：{{ ite.currentProgress }}%</span>
              </p> -->
            </div>
          </div>
          <!-- <my-tree v-if="item.lessonList" :data="item.lessonList"></my-tree> -->
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  name: "MyTree",
  props: ["data"],
  data() {
    return {
      isPlayId: null,
      activeName: null,
    };
  },
  mounted() {},
  computed: {
    list() {
      console.log(this.data);
      return this.data;
    },
  },
  methods: {
    videoHandle(item) {
      this.$emit("videoHandle", item);
      this.isPlayId = item.id;
    },
  },
};
</script>

<style lang="less" scoped>
.myTree {
  min-height: 100px;
  padding: 20px 30px;
  background: #fff;
}
.title {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #363840;
  .title_icon {
    display: block;
    width: 4px;
    height: 16px;
    background: #1a7ee2;
    margin-right: 10px;
  }
}
.collapse_second {
  padding: 0 30px;
  .content {
    height: 56px;
    display: flex;
    align-items: center;
    padding: 0 34px;
    cursor: pointer;

    .content_box {
      display: flex;
      width: 100%;
      justify-content: space-between;
      color: #999999;
      p {
        font-size: 14px;
        font-weight: 400;
        img {
          padding-right: 20px;
        }
        span {
          padding-left: 10px;
        }
      }
      .isplay {
        display: none;
      }
      .unplay {
        display: inline-block;
      }
    }
    &.isPlay {
      background: #f9f9f9;
      .content_box {
        color: #000;
        .isplay {
          display: inline-block;
        }
        .unplay {
          display: none;
        }
      }
    }
    &:hover {
      background: #f9f9f9;
    }
  }
}
</style>
