<template>
  <div class="study" v-loading="!studyList">
    <el-scrollbar class="elScroll">
      <div class="back-area" v-if="!isplaying">
        <el-button type="primary" plain @click="$router.go(-1)">返回上级</el-button>
      </div>
      <div v-if="!isplaying" class="courseInfo">
        <div class="course_img">
          <div
            class="bg"
            :style="
              info.smallPicture
                ? { 'background-image': 'url(' + info.smallPicture + ')' }
                : ''
            "
          >
            <p v-if="!info.smallPicture">{{ info.title }}</p>
          </div>
        </div>
        <div class="couseCont">
          <div class="tit">{{ info.title }}</div>
          <div class="text">
            <p><span>授课学校：</span>{{ info.companyName || "/" }}</p>
            <p><span>讲师：</span>{{ info.teacherName }}</p>
          </div>
          <div class="text">
            <p><span>总课程数：</span>{{ info.lessonNum }}</p>
            <p>
              <span>课程总时长：</span>
              {{
                [
                  $moment.duration(info.totalTime, "seconds").hours(),
                  $moment.duration(info.totalTime, "seconds").minutes(),
                  $moment.duration(info.totalTime, "seconds").seconds(),
                ].join(":")
              }}
            </p>
          </div>
        </div>
      </div>
      <view-courseware
        v-else
        :videoData="videoData"
        :id="id"
        @closeVideo="closeVideo"
      ></view-courseware>
      <MyTree
        v-if="studyList && studyList.length > 0"
        :data="studyList"
        @videoHandle="videoHandle"
        ref="mytree"
      ></MyTree>
    </el-scrollbar>
  </div>
</template>

<script>
import MyTree from "./MyTree.vue";
import viewCourseware from "./viewCourseware1.vue";
export default {
  data() {
    return {
      studyList: null,
      info: null,
      videoData: null,
      id: "",
      isplaying: false,
    };
  },
  // props: ["id"],
  components: {
    MyTree,
    viewCourseware,
  },
  mounted() {
    this.courseId = this.$route.query.courseId;
    this.getCourseTree();
  },
  methods: {
    // 获取图书列表
    getCourseTree() {
      this.$request.getScCourseTree(this.courseId).then((res) => {
        if (res.data.data.data.length > 0) {
          this.info = res.data.data.data[0];
          console.log(this.info);
          this.studyList = this.info.chapterList;
        } else {
          this.$message({
            message: "暂无视频可播放",
            type: "warning",
          });
        }
      });
    },

    videoHandle(item) {
      // this.$emit("videoHandle", item);
      this.videoData = item;
      this.isplaying = true;
    },
    closeVideo() {
      this.videoData = false;
      this.isplaying = false;
    },
  },
};
</script>

<style lang="less" scoped>
.courseInfo {
  display: flex;
  padding: 20px;
  .course_img {
    width: 249px;
    height: 140px;
    background: #ffffff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    position: relative;
    .bg {
      width: 249px;
      height: 140px;
      margin: 0 auto;
      background: url('../../../assets/course/zhuxueitembg.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      align-self: center;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        width: 80%;
        margin: 0 auto;
        text-align: center;
      }
    }
    .info {
      position: absolute;
      right: 0;
      top: 0;
      width: 58px;
      height: 20px;
      background: #e21a1a;
      box-shadow: 0px 2px 4px 0px rgba(91, 91, 91, 0.16);
      border-radius: 0px 8px 0px 8px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      text-align: center;
      &.pass {
        background: #1a7ee2;
      }
    }
  }
  .couseCont {
    display: flex;
    padding-left: 25px;
    flex-direction: column;
    justify-content: space-around;
    align-content: space-around;
    .tit {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #363840;
    }
    .text {
      display: flex;
      p {
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        min-width: 300px;
        span {
          color: #999999;
        }
      }
    }
  }
}
.study {
  // padding: 10px 30px;
  // min-height: 100px;
  height: 100%;
  background-color: #fff;
  .elScroll {
    height: 100%;
    /deep/ .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
}
.collapse_second {
  padding: 0 20px;
}
.back-area {
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
}
</style>
